<template>
  <div class="p-6">

    <a-form layout="inline" :form="form" class="form-label-4">
      <a-form-item label="项目">
        <a-select-pro
          class="form-control"
          v-decorator="['projectCode']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="projectList"
          @change="prjectChange"
          :dropdownMatchSelectWidth="false"
        ></a-select-pro>
      </a-form-item>
      <a-form-item label="班组">
        <a-select-pro
          class="form-control"
          v-decorator="['leaderId']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="leaderList"
          @change="leaderChange"
        ></a-select-pro>
      </a-form-item>
    </a-form>

    <br />

    <div class="info-box">
      <div class="info-item">
        <div class="info-title">姓名：</div>
        <div class="info-content">{{ detailData.username }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">当前项目：</div>
        <div class="info-content">{{ detailData.projectName }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">身份证：</div>
        <div class="info-content">{{ detailData.cardNumber }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">当前班组：</div>
        <div class="info-content">{{ detailData.leaderName }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">年龄：</div>
        <div class="info-content">{{ detailData.age }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">当前类别：</div>
        <div class="info-content">{{ detailData.workerKind }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">籍贯：</div>
        <div class="info-content">{{ detailData.nativePlace }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">当前工种：</div>
        <div class="info-content">{{ detailData.workerType }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">性别：</div>
        <div class="info-content">{{ detailData.sex }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">当前小工种：</div>
        <div class="info-content">{{ detailData.kindType }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">银行账号：</div>
        <div class="info-content">{{ detailData.bankAcount }}</div>
      </div>
      <div class="info-item">
        <div class="info-title">手机号：</div>
        <div class="info-content">{{ detailData.phone }}</div>
      </div>
    </div>
    <h3 class="mt-6">工作履历</h3>
    <div>
      <a-table
        rowKey="id"
        bordered
        :columns="tableColumns"
        :dataSource="dataSource"
        :pagination="false"
        size="small"
      ></a-table>
      <div class="flex text-lg">
        <div style="width: 80px; text-align: center">汇总</div>
         <div>
          应发金额/元:
          <span class="text-black">{{ needAmount }}</span>
        </div>
        <div class="ml-10">
          实发金额/元(实付工资+工友借支):
          <span class="text-black">{{ actualAmount }}</span>
        </div>
         <div class="ml-10">
          欠发金额/元:
          <span class="text-black">{{ backAmount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ASelectPro from '@/components/SelectPro.vue'
import { getProjectListByCard, getLeaderListByCard } from '@/service/getData'
export default {
  components: { ASelectPro },
  data() {
    const tableColumns = [
      {
        title: '区域',
        width: 80,
        align: 'center',
        dataIndex: 'areaName',
      },
      {
        title: '项目',
        width: '20%',
        align: 'left',
        dataIndex: 'projectName',
      },
      {
        title: '班组',
        width: '8%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '开始时间-结束时间',
        width: '15%',
        align: 'center',
        customRender: (text, row) => {
          return (
            <span>
              {moment(row.startTime).format('YYYY/MM/DD')}
              {' - '}
              {row.endTime ? moment(row.endTime).format('YYYY/MM/DD') : '暂无'}
            </span>
          )
        },
      },
      {
        title: '工种',
        width: '10%',
        align: 'left',
        dataIndex: 'workerType',
      },

      {
        title: '类别',
        width: '8%',
        align: 'center',
        dataIndex: 'workerKind',
      },
      {
        title: '小类别',
        width: '8%',
        align: 'center',
        dataIndex: 'kindType',
      },
       {
        title: '应发金额/元',
        width: '9%',
        align: 'center',
        dataIndex: 'needAmount',
      },
      {
        title: '实发金额/元(实付工资+工友借支)',
        width: '9%',
        align: 'center',
        dataIndex: 'actualAmount',
      },
       {
        title: '欠发金额/元',
        width: '9%',
        align: 'center',
        dataIndex: 'backAmount',
      },
    ]
    return {
      form: this.$form.createForm(this),
      projectList: [],
      cardId:"",
      leaderList: [],
      selectProjectCode:"",
      selectLeaderId:"",

      detailData: {},
      dataSource: [],
      tableColumns,
      needAmount: 0,
      actualAmount: 0,
      backAmount: 0,
    }
  },
  methods: {


    async prjectChange(value) {
      if (value !== null && value !== undefined && value !== '') {
        this.selectProjectCode = value;
      }else{
        this.selectProjectCode="";
      }
      await this.loadExperience(this.cardId);
    },
    async leaderChange(value) {
      if (value !== null && value !== undefined && value !== '') {
        this.selectLeaderId = value;
      }else{
        this.selectLeaderId ="";
      }
      await this.loadExperience(this.cardId);
    },
    async loadExperience(cardId) {
      const res = await this.$axios.post(`/report/evidence/chain/worker/experience/${cardId}-${this.selectProjectCode}-${this.selectLeaderId}`)
      this.dataSource = res.data.list
      this.needAmount = res.data.sum.needAmount
      this.actualAmount = res.data.sum.actualAmount
      this.backAmount = res.data.sum.backAmount
    },
    async loadWorkerInfo(param) {
      const res = await this.$axios.post(`/report/evidence/chain/worker/Info`, param)
      this.detailData = res.data
    },
  },
  async mounted() {
    const { cardId, projectCode, leaderId } = this.$route.query
    this.cardId=cardId
    this.projectList =await getProjectListByCard({ cardId })
    this.leaderList =await getLeaderListByCard({ cardId })
    await this.loadWorkerInfo({ cardId, projectCode, leaderId })
    await this.loadExperience(cardId)
  },
}
</script>

<style lang="less" scoped>
.info-box {
  display: grid;
  grid-template-columns: 50% 50%;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  .info-item {
    display: flex;
    border: 1px solid #ccc;
    border-right: 0;
    border-bottom: 0;
    height: 40px;

    .info-title {
      flex: 200px 0 0;
      border-right: 1px solid #ccc;
      background: #e5ebff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
    }

    .info-content {
      padding-left: 10px;
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
}
</style>
